import './MainHome.scss';
import React, { RefObject } from 'react';
import { publishService } from '../services/PublishService';
import { Category, Content, Publish } from "../common/types";
import logo from '../picture/logo.png';
import camera from '../picture/camera.png';
import frontPage from '../picture/frontPage.png';
import defaultCover from '../picture/defaultCover.jpg';
import { Link } from 'react-router-dom';
import { notNull } from "../common/utils";

interface HomeState {
  publish?: Publish;
  categories: Category[];
  publishContents: Content[];
  showContents: Content[];
  categoryId?: string;
  showMoreCategory: boolean;
}

const DEFAULT_PAGE_SIZE = 4;
const PC_DEFAULT_SHOW_CATEGORIES_SIZE = 8;
const PHONE_DEFAULT_SHOW_CATEGORIES_SIZE = 2;

export class MainHome extends React.Component<unknown, HomeState> {
  private publishId = notNull(new URLSearchParams(window.location.search).get('id'));
  private readonly loadMoreRef: RefObject<HTMLDivElement> = React.createRef();

  public constructor(props: unknown) {
    super(props);
    this.state = {
        categories: [],
        publishContents:[],
        showContents: [],
        categoryId: undefined,
        showMoreCategory: false,
    };
  }

  public async componentDidMount() : Promise<void> {
      document.title = await publishService.getPublishName(this.publishId);
      this.setState({
          publish: await publishService.getPublish(this.publishId),
          categories: await publishService.getCategories(this.publishId),
          publishContents: await this.sortPublishContents(),
      },() => {
          this.setInitShowContents();
      });
  }

    private async sortPublishContents() : Promise<Content[]> {
        const publishContents = await publishService.getContents(this.publishId,undefined, undefined, undefined);
        const categories = await publishService.getCategories(this.publishId);
        let categoryMaxIndex = 0;
        publishContents.forEach(content => {
            categoryMaxIndex = content.index > categoryMaxIndex ? content.index : categoryMaxIndex;
        });
        let sortPublishContents : Content[] = [];
        for (let i = 0; i <= categoryMaxIndex; i++) {
            [{id: undefined, name: '全部'}, ...categories].forEach((category) =>{
                sortPublishContents = [...sortPublishContents, ...publishContents.filter((c) => c.categoryId === category.id && c.index === i)]});
        }
        return sortPublishContents;
    }

  private setInitShowContents(): void {
      // load more
      new IntersectionObserver(entries => {
          if (entries[0].intersectionRatio <= 0) {
              return;
          }
          this.handleLoadMore();
      }).observe(notNull(this.loadMoreRef.current));
      this.setState({
          showContents: this.state.publishContents.length > DEFAULT_PAGE_SIZE ? this.state.publishContents.slice(0, DEFAULT_PAGE_SIZE) : this.state.publishContents,
      });
  }

  private showCategory(size: number): (Category | { id: undefined; name: string; })[] {
      return this.state.categories.length > size ?
          (this.state.showMoreCategory ? [{id: undefined, name: '全部'}, ...this.state.categories] : [{id: undefined, name: '全部'}, ...this.state.categories.slice(0,size)]) :
          [{id: undefined, name: '全部'}, ...this.state.categories]
  }

  private handShowMoreCategory(): void {
      this.setState({
          showMoreCategory: !this.state.showMoreCategory,
      })
  }

  public render(): JSX.Element {
    return (
        <div
            className="MainHome"
            style={{
                backgroundImage:`url(${this.state.publish?.backgroundImage})`}}
        >
            <div className="Top">
                <div className="TopImage">
                    <img src={frontPage} alt="frontPage"/>
                    <a href={`${this.state.publish?.url}`}>首页</a>
                    <img src={logo} alt="logo"/>
                </div>
            </div>
            <div className="MenuDiv">
                <ul className="Menu">
                    {
                        this.showCategory(PC_DEFAULT_SHOW_CATEGORIES_SIZE).map((category, index) =>
                            <li
                                key={index}
                                onClick={() => this.handleCategoryChanged(category.id)}
                                className="MenuItem">
                                <span className={`${this.state.categoryId === category.id ? 'active' : ''}`}>{category.id === undefined ? '全部' : category.name}</span>
                            </li>)
                    }
                    {
                        this.state.categories.length > PC_DEFAULT_SHOW_CATEGORIES_SIZE &&
                        <li className="PcShow"
                            onClick={() => this.handShowMoreCategory()}>
                            <span>
                                {
                                    this.state.showMoreCategory ? '收起' : '更多'
                                }
                            </span>
                        </li>
                    }
                </ul>
                <ul className="PhoneMenu">
                    {
                        this.showCategory(PHONE_DEFAULT_SHOW_CATEGORIES_SIZE).map((category, index) =>
                            <li
                                key={index}
                                onClick={() => this.handleCategoryChanged(category.id)}
                                className="PhoneMenuItem">
                                <span className={`${this.state.categoryId === category.id ? 'active' : ''}`}>{category.id === undefined ? '全部' : category.name}</span>
                            </li>)
                    }
                    {
                        this.state.categories.length > PHONE_DEFAULT_SHOW_CATEGORIES_SIZE &&
                        <li className="PhoneShow"
                            onClick={() => this.handShowMoreCategory()}>
                            <span>
                                {
                                    this.state.showMoreCategory ? '收起' : '更多'
                                }
                            </span>
                            <p className={`${this.state.showMoreCategory ? 'fas fa-angle-up' : 'fas fa-angle-down'}`}/>
                        </li>
                    }
                </ul>
            </div>
            <div className="Camera">
                <img src={camera} alt="camera"/>
                <span>慢直播视频</span>
            </div>
            <div className="Contents">
                <div className="ContentsDiv">
                    {
                        this.state.showContents.map((content, index) =>
                            <div className="ContentDiv">
                                <Link to={`/${this.publishId}/detail/${content.id}`}>
                                    <div key={index} className="Content">
                                        <img src={content.cover || defaultCover} alt='' />
                                        <div className="GradualChange">
                                            <div className="PcDescription">
                                                <p className="PcContentName">{content.name}</p>
                                                <p className="PcContentDescription" style={{color: "white"}}>{content.description}</p>
                                                <div className="Line"/>
                                                <p className="Details">查看详情</p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                <div className="PhoneDescription">
                                    <Link to={`/${this.publishId}/detail/${content.id}`}>
                                        <p className="PhoneContentName">{content.name}</p>
                                        <p className="PhoneContentDescription">{content.description}</p>
                                    </Link>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div ref={this.loadMoreRef}/>
                <p className="Copyright">北京国际云转播科技有限公司 版权所有 京ICP备2020044134号-3</p>
            </div>
        </div>
    );
  }

  private async handleCategoryChanged(categoryId?: string): Promise<void> {
      this.setState({
          categoryId: categoryId,
          publishContents: categoryId ?
              (await publishService.getContents(this.publishId,undefined,undefined, categoryId)) :
              (await this.sortPublishContents()),
      },() => {
          this.setInitShowContents();
      });
  }

  private handleLoadMore(): void {
      setTimeout(() => {
          this.setState({
              showContents: (this.state.publishContents.length - this.state.showContents.length) > DEFAULT_PAGE_SIZE ?
                  this.state.publishContents.slice(0,this.state.showContents.length + DEFAULT_PAGE_SIZE) :
                  this.state.publishContents,
          });
      },500);
  }
}
