import './index.scss';
import 'video.js/dist/video-js.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'antd/dist/antd.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import videojs from 'video.js';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { history } from './common/history';

const overlay = require('videojs-overlay').default;
videojs.registerPlugin('overlay', overlay);

ReactDOM.render(
    <Router history={ history }>
        <App />
    </Router>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
