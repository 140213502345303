import './Navigation.scss';
import React from 'react';
import { Input, InputNumber, Pagination, Switch } from 'antd';

interface NavigationProps {
  visible: boolean;
  scenicPlace?: string;
  pageIndex: number;
  pageSize: number;
  totalContents: number
  autoScroll: boolean;
  autoScrollInterval: number;
  handleCityChanged: (city: string) => void;
  handleSearch: (search?: string) => void;
  handlePageChanged: (pageIndex: number) => void;
  handleAutoScrollChanged: (autoScroll: boolean) => void;
  handleAutoScrollIntervalChanged: (autoScrollInterval: number) => void;
  handleVisibleChanged: (visible: boolean) => void;
}

interface NavigationState {
  search?: string;
}

const cities = [
  '全部',
  '南宁市',
  '柳州市',
  '桂林市',
  '梧州市',
  '北海市',
  '防城港市',
  '钦州市',
  '贵港市',
  '玉林市',
  '百色市',
  '贺州市',
  '河池市',
  '来宾市',
  '崇左市'
];

export class Navigation extends React.Component<NavigationProps, NavigationState> {

  public constructor(props: NavigationProps) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <div className="Navigation">
        <div className={`Content ${this.props.visible ? '' : 'collapse'}`}>
          <div className="SearchContainer">
            <ul className="Menu">
              {
                cities.map((c, index) =>
                  <li
                    key={index}
                    className={`MenuItem ${(c === this.props.scenicPlace || (c === '全部' && !this.props.scenicPlace)) ? 'active' : ''}`}
                    onClick={() => this.props.handleCityChanged(c)}>
                    {c}
                  </li>)
              }
            </ul>
            <Input
              className="Search"
              prefix={<i onClick={() => this.handleSearch()} className="fas fa-search" />}
              value={this.state.search}
              onChange={e => this.handleSearchChanged(e.target.value)}
              onPressEnter={() => this.handleSearch()}
            />
          </div>
          <div className="PaginationContainer">
            <Pagination
              showSizeChanger={false}
              current={this.props.pageIndex + 1}
              pageSize={this.props.pageSize}
              total={this.props.totalContents}
              onChange={index => this.props.handlePageChanged(index - 1)}
            />
            <div className="AutoScrollContainer">
              <label>自动翻页</label>
              <Switch
                checked={this.props.autoScroll}
                onChange={checked => this.props.handleAutoScrollChanged(checked)}
              />
            </div>
            <div className="AutoScrollIntervalContainer">
              <label>翻页间隔（秒）</label>
              <InputNumber
                disabled={!this.props.autoScroll}
                min={10}
                value={this.props.autoScrollInterval}
                onChange={value => this.props.handleAutoScrollIntervalChanged(value === null ? 10 : value)}
              />
            </div>
          </div>
        </div>
        <button
          className={`DropDown ${this.props.visible ? 'fas fa-angle-up' : 'fas fa-angle-down'}`}
          onClick={() => this.props.handleVisibleChanged(!this.props.visible)}
        />
      </div>
    );
  }

  private handleSearchChanged(search: string): void {
    this.setState({
      search: search,
    });
  }

  private handleSearch(): void {
    this.props.handleSearch(this.state.search || undefined);
  }
}
