import axios from 'axios';
import { PUBLISH_BASE_URL } from '../common/constants';
import { Category, Content, Publish } from '../common/types';
import { replaceUrlParams } from '../common/utils';

const GET_PUBLISH_NAME_URL = `${PUBLISH_BASE_URL}/v1/publishes/:publishId/name`;
const GET_CONTENTS_URL = `${PUBLISH_BASE_URL}/v1/publishes/:publishId/contents`;
const GET_PUBLISH_URL = `${PUBLISH_BASE_URL}/v1/publishes/:publishId`;
const GET_CATEGORIES_URL = `${PUBLISH_BASE_URL}/v1/publishes/:publishId/categories`;
const GET_CONTENT_URL = `${PUBLISH_BASE_URL}/v1/publishes/:publishId/contents/:contentId`;

class PublishService {

  public async getPublishName(publishId: string): Promise<string> {
    return (await axios.get(replaceUrlParams(GET_PUBLISH_NAME_URL, { publishId }))).data.name;
  }

  public async getContents(publishId: string, scenicPlace?: string, name?: string, categoryId?: string): Promise<Content[]> {
    return (await axios.get(replaceUrlParams(GET_CONTENTS_URL, { publishId }), {
      params: {
        name: name,
        scenicPlace: scenicPlace,
        categoryId: categoryId,
      }
    })).data as Content[];
  }

  public async getPublish(publishId: string): Promise<Publish> {
    return (await axios.get(replaceUrlParams(GET_PUBLISH_URL, { publishId }))).data;
  }

  public async getCategories(publishId: string): Promise<Category[]> {
    return (await axios.get(replaceUrlParams(GET_CATEGORIES_URL, { publishId }))).data as Category[];
  }

  public async getContent(publishId: string, contentId: string): Promise<Content> {
    return (await axios.get(replaceUrlParams(GET_CONTENT_URL, { publishId, contentId }))).data as Content;
  }

}

export const publishService = new PublishService();
