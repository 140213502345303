import './Home.scss';
import 'swiper/swiper-bundle.css';
import React from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { message } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';
import { Content } from '../../common/types';
import { GUANGXI_LIVE_PUBLISH_ID, GUANGZHOU_LIVE_PUBLISH_ID, HIDE_LOGIN, ZHONGXI_LIVE_PUBLISH_ID } from '../../common/constants';
import { publishService } from '../../services/PublishService';
import { ContentView } from './ContentView';
import { Navigation as NavigationView } from './Navigation';
import { authService } from '../../services/AuthService';
import { Login } from './Login';

// install Swiper modules
SwiperCore.use([Navigation]);

interface Page {
  contents: Content[];
}

interface HomeState {
  login?: boolean;
  page: number;
  totalContents: number;
  pages: Page[];
  scenicPlace?: string;
  search?: string;
  showNavigation: boolean;
  muted: boolean;
  autoScroll: boolean;
  autoScrollInterval: number;
}

interface HomeProps {
  location?: string
}

const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_PAGE_INDEX = 0;
const DEFAULT_AUTO_SCROLL = false;
const DEFAULT_AUTO_SCROLL_INTERVAL = 120;

function getPages(contents: Content[]): Page[] {
  const pages: Page[] = [];
  for (let i = 0; i < contents.length; i += DEFAULT_PAGE_SIZE) {
    pages.push({
      contents: contents.slice(i, Math.min(i + DEFAULT_PAGE_SIZE, contents.length)),
    });
  }
  return pages;
}

function getUrlParams(): { page: number; autoScroll: boolean; autoScrollInterval: number; scenicPlace?: string; search?: string } {
  const params = new URLSearchParams(window.location.search);
  return {
    page: params.get('page') ? Number(params.get('page')) - 1 : DEFAULT_PAGE_INDEX,
    autoScroll: params.get('autoScroll') ? params.get('autoScroll') === 'true' : DEFAULT_AUTO_SCROLL,
    autoScrollInterval: params.get('autoScrollInterval') ? Number(params.get('autoScrollInterval')) : DEFAULT_AUTO_SCROLL_INTERVAL,
    scenicPlace: params.get('scenicPlace') || undefined,
    search: params.get('search') || undefined,
  };
}

function updateOrDeleteUrlParam<T extends boolean | number | string | undefined>(params: URLSearchParams, paramName: string, currentValue: T, defaultValue: T) {
  if (currentValue === defaultValue) {
    params.delete(paramName);
  } else {
    params.set(paramName, String(currentValue));
  }
}

export class Home extends React.Component<HomeProps, HomeState> {
  private publishId = this.props.location === 'guangxi' ? GUANGXI_LIVE_PUBLISH_ID : this.props.location === 'guangzhou' ? GUANGZHOU_LIVE_PUBLISH_ID : this.props.location === 'zhongxi' ? ZHONGXI_LIVE_PUBLISH_ID : '';
  private swiper?: SwiperClass;
  private autoScrollTimer?: number;

  public constructor(props: HomeProps) {
    super(props);
    this.state = {
      page: DEFAULT_PAGE_INDEX,
      totalContents: 0,
      pages: [],
      showNavigation: false,
      muted: true,
      autoScroll: DEFAULT_AUTO_SCROLL,
      autoScrollInterval: DEFAULT_AUTO_SCROLL_INTERVAL,
    };
  }

  public async componentDidMount() {
    document.title = await publishService.getPublishName(this.publishId);
    this.setState({
      ...getUrlParams(),
      login: HIDE_LOGIN || this.props.location === 'guangzhou' || this.props.location === 'zhongxi' ? true : authService.checkLogin(),
      muted: this.props.location !== 'zhongxi',
    }, async () => {
      if (this.state.login) {
        await this.refresh((new URLSearchParams(window.location.search).get('page')) === null ?
            0 : Number(new URLSearchParams(window.location.search).get('page')) - 1);
        this.resetAutoScrollTimer();
      }
    });
  }

  public render() {
    return (
      <div className="Home">
        {
          this.state.login === undefined ? null :
            !this.state.login ?
              <Login onLogin={(username: string, password: string) => this.login(username, password)}/> :
              <>
                {
                  this.props.location === 'guangxi' &&
                  <NavigationView
                    visible={this.state.showNavigation}
                    scenicPlace={this.state.scenicPlace}
                    pageIndex={this.state.page}
                    pageSize={DEFAULT_PAGE_SIZE}
                    totalContents={this.state.totalContents}
                    autoScroll={this.state.autoScroll}
                    autoScrollInterval={this.state.autoScrollInterval}
                    handleVisibleChanged={visible => this.handleNavigationVisibleChanged(visible)}
                    handleCityChanged={city => this.handleCityChanged(city)}
                    handleSearch={search => this.handleSearch(search)}
                    handlePageChanged={pageIndex => this.handlePageChanged(pageIndex)}
                    handleAutoScrollChanged={autoScroll => this.handleAutoScrollChanged(autoScroll)}
                    handleAutoScrollIntervalChanged={interval => this.handleAutoScrollIntervalChanged(interval)}
                  />
                }
                <Swiper
                  className="Swiper"
                  navigation={this.props.location !== 'zhongxi'}
                  onSwiper={swiper => this.swiper = swiper}
                  onSlideChange={swiper => this.onSlideChange(swiper)}
                  allowTouchMove={false}
                  style={{ height: this.state.totalContents >= 20 ? '100%' : 'auto' }}
                >
                  {
                    this.state.pages.map((page, index) =>
                      <SwiperSlide className="SwiperSlide"
                                   style={this.props.location === 'zhongxi' ?
                                       { gridTemplateColumns: "repeat(2, 1fr)" } :
                                       { height: `${ Math.ceil(page.contents.length / 5) * 25 }%` }}
                                   key={index}>
                        {
                          ({ isActive }) => page.contents.map((content) =>
                            <ContentView
                              key={content.id}
                              content={content}
                              autoPlay={true}
                              muted={this.state.muted}
                              active={isActive}
                            />
                          )
                        }
                      </SwiperSlide>
                    )
                  }
                </Swiper>
                {
                  this.props.location === 'zhongxi' &&
                  <div className='text'>
                    法律声明：中央戏剧学院依法享有该作品的全部知识产权。<br/>
                    严禁任何形式的侵权行为，包括但不限于对作品或其组成部分进行录屏、转载、下载、商用等操作。
                    严禁以任何形式建立作品镜像。
                    中央戏剧学院保留一切追究法律责任的权利。
                  </div>
                }
              </>
        }
      </div>
    );
  }

  private handleNavigationVisibleChanged(visible: boolean): void {
    this.setState({
      showNavigation: visible,
    });
  }

  private async handleSearch(search?: string): Promise<void> {
    this.setState({
      search: search,
      scenicPlace: undefined,
    }, () => this.refresh());
  }

  private onSlideChange(swiper: SwiperClass): void {
    this.handlePageChanged(swiper.activeIndex);
  }

  private async handleCityChanged(city: string): Promise<void> {
    this.setState({
      search: undefined,
      scenicPlace: city === '全部' ? undefined : city,
    }, () => this.refresh());
  }

  private handlePageChanged(pageIndex: number): void {
    this.setState({
      page: pageIndex,
    }, () => {
      if (this.swiper?.activeIndex !== this.state.page) {
        this.swiper?.slideTo(this.state.page);
      }
      this.updateUrlParams();
    });
  }

  private handleAutoScrollChanged(autoScroll: boolean): void {
    this.setState({
      autoScroll: autoScroll,
    }, () => {
      this.resetAutoScrollTimer();
      this.updateUrlParams();
    });
  }

  private handleAutoScrollIntervalChanged(interval: number): void {
    this.setState({
      autoScrollInterval: interval,
    }, () => {
      this.resetAutoScrollTimer();
      this.updateUrlParams();
    });
  }

  private async login(username: string, password: string): Promise<void> {
    try {
      await authService.login(username, password);
      this.setState({
        login: true,
      });
      await this.refresh();
      this.resetAutoScrollTimer();
    } catch (e) {
      message.error(`登录失败`);
    }
  }

  private async refresh(historicalPage? : number) {
    const contents = await publishService.getContents(this.publishId, this.state.scenicPlace, this.state.search);
    this.setState({
      page: historicalPage || 0,
      totalContents: contents.length,
      pages: getPages(contents),
    }, () => {
      this.handlePageChanged(this.state.page);
      this.updateUrlParams();
      this.swiper?.update();
    });
  }

  private resetAutoScrollTimer(): void {
    if (this.autoScrollTimer !== undefined) {
      window.clearInterval(this.autoScrollTimer);
      this.autoScrollTimer = undefined;
    }
    if (this.state.autoScroll) {
      this.autoScrollTimer = window.setInterval(
        () => this.scrollToNextPage(),
        this.state.autoScrollInterval * 1000
      );
    }
  }

  private scrollToNextPage(): void {
    const lastPage = this.state.totalContents === 0 ? 0 : Math.ceil(this.state.totalContents / DEFAULT_PAGE_SIZE - 1);
    const nextPage = this.state.page >= lastPage ? 0 : this.state.page + 1;
    this.handlePageChanged(nextPage);
  }

  private updateUrlParams(): void {
    const url = new URL(window.location.href);
    updateOrDeleteUrlParam(url.searchParams, 'page', this.state.page + 1, DEFAULT_PAGE_INDEX + 1);
    updateOrDeleteUrlParam(url.searchParams, 'autoScroll', this.state.autoScroll, DEFAULT_AUTO_SCROLL);
    updateOrDeleteUrlParam(url.searchParams, 'autoScrollInterval', this.state.autoScrollInterval, DEFAULT_AUTO_SCROLL_INTERVAL);
    updateOrDeleteUrlParam(url.searchParams, 'scenicPlace', this.state.scenicPlace || undefined, undefined);
    updateOrDeleteUrlParam(url.searchParams, 'search', this.state.search || undefined, undefined);
    window.history.replaceState(null, '', url.toString());
  }
}
