import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import 'video.js/dist/video-js.min.css';
import videojs from 'video.js';
import { notNull } from '../common/utils';
import './Detail.scss';
import frontPage from '../picture/frontPage.png';
import logo from '../picture/logo.png';
import goBack from './img/goBack.png';
import { Content, Publish } from '../common/types';
import { publishService } from "../services/PublishService";

export interface ContentDetailsProps extends RouteComponentProps<{ publishId: string, contentId: string }> {
}

export interface ContentDetailsState {
    content?: Content;
    publish?: Publish;
}

export class Detail extends React.Component<ContentDetailsProps, ContentDetailsState> {
    private videoRef: React.RefObject<HTMLVideoElement> = React.createRef();
    private player? : videojs.Player;

    public constructor(props: ContentDetailsProps) {
        super(props);
        this.state = {
            content: undefined,
            publish: undefined,
        }
    }

    public async componentDidMount(): Promise<void> {
        const playerOptions : videojs.PlayerOptions = {
            controlBar: {
                volumePanel: {
                    inline: false,
                    volumeControl: {
                        vertical: true
                    }
                }
            }
        };
        this.setState({
            content: await publishService.getContent(this.props.match.params.publishId, this.props.match.params.contentId),
            publish: await publishService.getPublish(this.props.match.params.publishId),
        })
        this.player = videojs(notNull(this.videoRef.current), playerOptions);
        this.player.src(this.state.content?.hlsPlaybackUrl ? this.state.content.hlsPlaybackUrl : '');
    }

    public render(): JSX.Element {
        return (
            <div
                className="Detail"
                style={{backgroundImage:`url(${this.state.publish?.backgroundImage})`}}
            >
                <div className="Div">
                    <div className="Top">
                        <div className="TopImage">
                            <img src={frontPage} alt="frontPage"/>
                            <a href={`${this.state.publish?.url}`} className="front">首页</a>
                            <a href={`${this.state.publish?.url}`}><img src={goBack} alt="goBack" className="goBack"/></a>
                            <img src={logo} alt="logo"/>
                        </div>
                    </div>
                    <div className="VideoDiv">
                        <video className="video-js vjs-big-play-centered"
                               ref={this.videoRef}
                               controls
                        />
                        <div className="Description">
                            <p>{this.state.content?.name}</p>
                            <div className="Line"/>
                            <p>景区介绍</p>
                            <p>{this.state.content?.description}</p>
                        </div>
                    </div>
                    <div className="Copyright"><p>北京国际云转播科技有限公司 版权所有 京ICP备2020044134号-3</p></div>
                </div>
            </div>
        );
    }
}
