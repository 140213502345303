export const PUBLISH_BASE_URL = process.env.REACT_APP_PUBLISH_BASE_URL || '';

export const AUTH_BASE_URL = process.env.REACT_APP_AUTH_BASE_URL || '';

export const HOST_NAME = process.env.REACT_APP_HOST_NAME || '';

export const HIDE_LOGIN = process.env.REACT_APP_HIDE_LOGIN === 'true';

export const GUANGXI_LIVE_HOSTNAME = 'guangxilive.icbtlive.com';

export const GUANGXI_LIVE_PUBLISH_ID = 'e1d280ff-d123-4256-97c5-79e7f529a3ce';

export const GUANGZHOU_LIVE_PUBLISH_ID = 'bf2fca52-36aa-4be2-8341-3905f0a14ebe';

export const ZHONGXI_LIVE_PUBLISH_ID = '2283ed1b-bab8-40bd-93f6-ccc357268b59';

