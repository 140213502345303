import { AUTH_BASE_URL } from '../common/constants';
import axios from 'axios';
import { LoginResponse } from '../common/types';

const STORAGE_ITEM_TOKEN = 'token';

const LOGIN_URL = `${AUTH_BASE_URL}/v1/token`;

class AuthService {

  public checkLogin(): boolean {
    return !!sessionStorage.getItem(STORAGE_ITEM_TOKEN);
  }

  public async login(username: string, password: string): Promise<void> {
    const response = (await axios.post(LOGIN_URL, { username, password })).data as LoginResponse;
    sessionStorage.setItem(STORAGE_ITEM_TOKEN, response.token);
  }
}

export const authService = new AuthService();
