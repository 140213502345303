import { HOST_NAME } from './constants';

export function notNull<T>(value: T | null | undefined): T {
  if (value === null || value === undefined) {
    throw new Error(`value is null`);
  }
  return value;
}

export function replaceUrlParams(url: string, params: object): string {
  Object.entries(params).forEach(([key, value]) => {
    url = url.replace(`:${key}`, value);
  });
  return url;
}

export function getHostname(): string {
  return HOST_NAME || new URL(window.location.href).hostname;
}
