import React from 'react';
import { Home as GuangXiLiveHome } from './custom/guangxilive/Home';
import { MainHome } from './page/MainHome'
import { GUANGXI_LIVE_HOSTNAME } from './common/constants';
import { getHostname } from './common/utils';
import { Route, Switch } from "react-router-dom";
import { Detail } from "./page/Detail";

export class App extends React.Component {

  public render() {
    let Home: JSX.Element = <MainHome/>;
    const hostname = getHostname();
    if (hostname === GUANGXI_LIVE_HOSTNAME) {
      Home = <GuangXiLiveHome location = 'guangxi'/>
    }
    if (new URLSearchParams(window.location.search).get('id') === 'bf2fca52-36aa-4be2-8341-3905f0a14ebe') {
      Home = <GuangXiLiveHome location = 'guangzhou'/>
    }
    if (new URLSearchParams(window.location.search).get('id') === '2283ed1b-bab8-40bd-93f6-ccc357268b59') {
      Home = <GuangXiLiveHome location = 'zhongxi'/>
    }

    return (
        <div className="App">
          <Switch>
            <Route key= "Home" path={'/'} exact component = { () => Home }/>
            <Route key= "Detail" path={'/:publishId/detail/:contentId'} exact component = { Detail }/>
          </Switch>
        </div>
    );
  }
}
